import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import classNames from 'classnames';
import { HelpIcon } from '@/core/Icon.atom';

export interface LabelIF extends ValidatingFormComponent<string | React.ReactNode> {
  component: 'LabelFormComponent';
  noMarginBottom?: boolean;
  onIconClick?: () => any;
  valueCustomParams?: Record<string, string | number>;
  onClick?: () => void;
}

const LabelFormComponentUnwrapped: React.FunctionComponent<LabelIF> = ({
  value,
  tooltip,
  onIconClick,
  onClick,
  testId,
  extraClassNames,
  noMarginBottom,
  tooltipDelay,
  valueCustomParams,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('flexColumnContainer', extraClassNames, {
        mb5: !noMarginBottom,
      })}
      onClick={onClick}
      data-testid={testId}>
      {_.isString(value) ? t(value, valueCustomParams) : value}
      {tooltip && (
        <HelpIcon
          tooltip={tooltip}
          tooltipDelay={tooltipDelay}
          extraClassNames="pl5"
          testId={`${testId}_icon`}
          onClick={_.isFunction(onIconClick) ? onIconClick : _.noop}
        />
      )}
    </div>
  );
};

export const LabelFormComponent = React.memo(
  LabelFormComponentUnwrapped,
  (prev, next) =>
    !(prev.value !== next.value || prev.tooltip !== next.tooltip || next.extraClassNames !== prev.extraClassNames),
);
