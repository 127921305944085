// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import SelectUnit from '@/core/SelectUnit.molecule';
import {
  canUseMemo,
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { HelpIcon } from '@/core/Icon.atom';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface SelectUnitIF extends ValidatingFormComponent<string> {
  component: 'SelectUnitFormComponent';
  disabled?: boolean;
  label?: string;
}

const SelectUnitFormComponentUnwrapped: React.FunctionComponent<SelectUnitIF> = (props) => {
  const { name, validation, extendValidation, disabled, extraClassNames, label, tooltip } = props;
  const { t } = useTranslation();

  const defaultValidation = (value) => _.isEmpty(value);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper {...getFormFieldWrapperProps(props, ['id', 'label'])}>
      <Field name={name} validate={getValidationFunction(defaultValidation, extendValidation, validation)}>
        {({ input, meta }) => (
          <div className={extraClassNames}>
            {label && (
              <div className="mb5">
                {t(label)}
                {tooltip && <HelpIcon tooltip={tooltip} extraClassNames="pl5" />}
              </div>
            )}
            <SelectUnit
              extraClassNames={extraClassNames}
              {...getFormFieldProps(formState, input, meta, props)}
              isDisabled={disabled}
              menuPortalTarget={document.body}
            />
          </div>
        )}
      </Field>
    </FormFieldWrapper>
  );
};
export const SelectUnitFormComponent = React.memo(SelectUnitFormComponentUnwrapped, canUseMemo);
