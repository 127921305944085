// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.js';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/matchbrackets';
import { FormulaEditorParam } from '@/formula/FormulaParametersTable.molecule';
import { useTranslation } from 'react-i18next';
import { addTextAtCursor, getAutocompleteHints, getContextHelp, getErrorMessage } from '@/formula/formula.utilities';
import { Icon } from '@/core/Icon.atom';
import SelectUnit from '@/core/SelectUnit.molecule';
import { useCurrentValueRef } from '@/core/hooks/useCurrentValueRef.hook';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { sqFormulaToolStore, sqWorksheetStore } from '@/core/core.stores';
import { setDisplayResizeEnabled } from '@/worksheet/worksheet.actions';
import { setFormulaFilter, setNavigationStack, toggleHelp } from '@/tools/formula/formulaTool.actions';

export interface FormulaErrorInterface {
  column: number;
  line: number;
  message: string;
}

interface FormulaEditorProps {
  formula: string;
  constants: any[];
  operators: any[];
  showLineNumbers: boolean;
  exposeFormulaToParent: (formula: string) => void;
  exposeEditorToParent: (editor: any) => void;
  parameters: FormulaEditorParam[];
  onSave: (formula: string) => any;
  formulaErrors?: FormulaErrorInterface[];
  setFormulaErrors?: (errors: any) => void;
  readOnly?: boolean;
}

export const FormulaEditorUnwrapped: React.FunctionComponent<FormulaEditorProps> = ({
  parameters,
  constants,
  operators,
  showLineNumbers,
  formula = '',
  exposeFormulaToParent,
  exposeEditorToParent,
  onSave,
  formulaErrors,
  setFormulaErrors,
  readOnly = false,
}) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState(null);
  const [code, setCode] = useState(formula);
  const [widgets, setWidgets] = useState([]);

  const clearError = () => {
    if (editor && _.isFunction(setFormulaErrors)) {
      setFormulaErrors([]);
    }
  };

  useEffect(() => {
    if (editor) {
      _.forEach(widgets, (widget) => editor.removeLineWidget(widget));
      setWidgets([]);

      _.forEach(formulaErrors, ({ message, line }) => {
        const error = getErrorMessage(message, clearError);
        setWidgets([editor.addLineWidget(line > -1 ? line - 1 : 0, error, {})]);
      });
    }
  }, [editor, formulaErrors]);

  useEffect(() => {
    setCode(formula);
  }, [formula]);

  const doAutocompleteHints = () => getAutocompleteHints(editor, constants, operators, parameters);
  const autoCompleteRef = useCurrentValueRef(doAutocompleteHints);

  const contextHelp = () => {
    const operatorMatches = getContextHelp(editor, operators);

    if (_.has(_.first(operatorMatches), 'documentationHref')) {
      // Documentation is available for the operator under the cursor
      setNavigationStack(_.concat(sqFormulaToolStore.navigationStack, operatorMatches[0].documentationHref));
    } else if (_.size(operatorMatches)) {
      // Specific documentation wasn't found, so do a search instead
      setFormulaFilter(operatorMatches);
    }
    if (!sqFormulaToolStore.helpShown) {
      toggleHelp();
    }

    if (!sqWorksheetStore.resizeEnabled) {
      setDisplayResizeEnabled(true);
    }
  };

  const doSave = () => onSave(code);

  const insertUnit = (unit) => {
    addTextAtCursor(unit, editor);
    // slightly hacky way to close the units dropdown
    _.isFunction(document.body.click) && document.body.click();
    editor.focus();
  };

  const hintOptions = {
    hint: doAutocompleteHints,
    container: document.getElementById('formulaEditor'),
    closeOnUnfocus: true,
    completeSingle: false,
    delay: 500,
  };

  return (
    <div className="flexRowContainer flexFill mt0">
      <div className="flexColumnContainer mb2">
        <div className="flexColumnContainer width-maximum">
          <ButtonWithPopover
            label={
              <Icon
                icon="fc-unit"
                extraClassNames="fa-fw width-20"
                type="theme"
                testId="formulaUnits"
                tooltip="FORMULA.UNITS_TOOLTIP"
                tooltipPlacement="top"
              />
            }
            closeOnClick={false}
            popoverConfig={{
              id: 'formulaUnits',
              placement: 'bottom-start',
              wrapperClassNames: 'forceZeroPadding forceNoBorder',
            }}>
            <SelectUnit placeholder="UNITS_PLACEHOLDER" isClearable={false} menuIsOpen={true} onChange={insertUnit} />
          </ButtonWithPopover>
          <Icon
            testId="formulaContextHelp"
            onClick={contextHelp}
            extraClassNames="cursorPointer"
            icon="fa-question-circle"
            tooltip="FORMULA.F1_HELP"
            tooltipPlacement="top"
          />
        </div>
      </div>
      <div id="formulaEditor" className="formula-border flexFill">
        <CodeMirror
          value={code}
          editorDidMount={(codeMirrorEditor) => {
            setEditor(codeMirrorEditor);
            exposeEditorToParent(codeMirrorEditor);
          }}
          options={{
            placeholder: t('FORMULA.FORMULA_PLACEHOLDER'),
            matchBrackets: true,
            mode: { name: 'application/x-httpd-php', startOpen: true },
            smartIndent: false,
            theme: 'seeq',
            readOnly,
            cursorHeight: readOnly ? 0 : 1,
            lineWrapping: !code,
            lineNumbers: showLineNumbers,
            extraKeys: {
              'Ctrl-Space': 'autocomplete',
              'Ctrl-H': contextHelp, // for testing as F1 is finickey
              'Ctrl-S': doSave,
              'F1': contextHelp,
              // Enable tabbing through the form by disabling tab and shift tab
              'Tab': false,
              'Shift-Tab': false,
            },
            hintOptions,
          }}
          onInputRead={(editor) =>
            editor.showHint({
              hint: autoCompleteRef.current,
              container: document.getElementById('formulaEditor'),
            })
          }
          onBeforeChange={(editor, data, value) => setCode(value)}
          onChange={(editor, data, value) => {
            clearError();
            exposeFormulaToParent(value);
          }}
        />
      </div>
    </div>
  );
};

export const FormulaEditor = React.memo(
  FormulaEditorUnwrapped,
  (prev, next) =>
    !(
      prev.formula !== next.formula ||
      !_.isEqual(next.formulaErrors, prev.formulaErrors) ||
      !_.isEqual(next.operators, prev.operators) ||
      !_.isEqual(next.constants, prev.constants) ||
      !_.isEqual(next.parameters, prev.parameters)
    ),
);
