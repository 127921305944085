import React, { ReactNode } from 'react';
import { FakeLink } from '@/core/FakeLink';

export interface FormulaLinkProps {
  href: string;
  requestDocumentation: (href: string) => void;
  children?: ReactNode;
}

export const FormulaLink: React.FunctionComponent<FormulaLinkProps> = ({ href, requestDocumentation, children }) => (
  <FakeLink onClick={() => requestDocumentation(href)}>{children}</FakeLink>
);
