import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormError } from '@/core/FormError.atom';
import { HelpIcon } from '@/core/Icon.atom';
import { IconWithPopoverTooltip } from '@/core/IconWithPopoverTooltip.molecule';
import { Placement } from 'react-bootstrap/esm/Overlay';

export interface FormFieldWrapperProps {
  testId?: string;
  children: React.ReactElement;
  label?: string | React.ReactNode;
  id?: string;
  extraClassNames?: string;
  wrapperClassNames?: string;
  showError?: boolean;
  customErrorText?: string;
  customErrorParams?: Record<string, any>;
  tooltip?: string;
  tooltipPlacement?: Placement;
  onTooltipClick?: () => void;
  popoverContent?: React.ReactNode;
}

export const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({
  label,
  extraClassNames,
  testId = 'formComponent',
  children,
  showError,
  customErrorText,
  customErrorParams = {},
  wrapperClassNames,
  tooltip,
  tooltipPlacement,
  onTooltipClick,
  popoverContent,
  id,
}) => {
  const { t } = useTranslation();

  const renderLabel = () => {
    if (typeof label !== 'string') {
      return label;
    }

    const labelTestId = `${testId}Label`;

    if (label && (tooltip || popoverContent)) {
      return (
        <div className="flexColumnContainer mb5" data-testid={labelTestId}>
          <span className="mr5">{t(label)}</span>
          {tooltip ? (
            <HelpIcon
              tooltip={tooltip}
              testId={`${labelTestId}_icon`}
              tooltipPlacement={tooltipPlacement}
              onClick={onTooltipClick}
            />
          ) : (
            <IconWithPopoverTooltip
              trigger="click"
              placement="top"
              rootClose={true}
              testId={`${labelTestId}Popover`}
              content={popoverContent}
            />
          )}
        </div>
      );
    }

    return (
      <span className="mb5" data-testid={labelTestId}>
        {t(label)}
      </span>
    );
  };

  const renderChildren = () => {
    if (!label && tooltip) {
      return (
        <div className="flexColumnContainer flexAlignCenter flexFill">
          {children}
          <HelpIcon tooltip={tooltip} extraClassNames="ml5" onClick={onTooltipClick} />
        </div>
      );
    }

    return children;
  };

  return (
    <div id={id} className={classNames('flexRowContainer mb8', wrapperClassNames)} data-testid={testId}>
      {renderLabel()}
      <div className={extraClassNames} data-testid={`${testId}FieldWrapper`}>
        {renderChildren()}
      </div>
      {showError && <span data-testid="showerror" />}
      {showError && customErrorText && (
        <FormError
          errorText={customErrorText}
          errorParameters={customErrorParams}
          dataTestId={`${testId}Error`}
          extraClassNames="mt5"
        />
      )}
    </div>
  );
};
