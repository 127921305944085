import React from 'react';
import _ from 'lodash';
import { Field } from 'react-final-form';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { RadioButtonGroup, RadioButtonOption } from '@/core/RadioButtonGroup.molecule';

export interface RadioButtonsIF extends ValidatingFormComponent<any> {
  component: 'RadioButtonGroupFormComponent';
  options: RadioButtonOption[];
  id: string;
  /** If true, the options will be displayed in a 2 column grid instead of a row */
  gridLayout?: boolean;
  verticalLayout?: boolean;
  fieldExtraClassNames?: string;
  checkboxExtraClassNames?: string;
}

/**
 * This component renders multiple checkboxes in a FlexRowContainer.
 * If you prefer to control the layout of your checkboxes you can use CheckboxFormComponent and a combination of
 * FORM_ROW, FORM_GROUP and FORM_COMPONENT.
 */
export const RadioButtonGroupFormComponent: React.FunctionComponent<RadioButtonsIF> = (props) => {
  const {
    id,
    label,
    tooltip,
    options,
    name,
    testId = 'radioButtonGroup',
    extraClassNames,
    fieldExtraClassNames,
    checkboxExtraClassNames,
    gridLayout = false,
    verticalLayout,
  } = props;

  return (
    <FormFieldWrapper id={id} testId={testId} label={label} tooltip={tooltip} wrapperClassNames={extraClassNames}>
      <Field name={name}>
        {({ input }) => (
          <RadioButtonGroup
            options={options}
            verticalLayout={verticalLayout}
            gridLayout={gridLayout}
            onChange={input.onChange}
            extraClassNames={fieldExtraClassNames}
            checkboxExtraClassNames={checkboxExtraClassNames}
          />
        )}
      </Field>
    </FormFieldWrapper>
  );
};
